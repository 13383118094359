import React, { ReactElement, ReactNode } from 'react'
import { Tooltip } from '@mui/material'
import styles from './styledTooltip.module.scss'
import zIndex from '@mui/material/styles/zIndex'

type Props = {
  children: ReactElement
  type?: 'default' | 'error'
  position?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
  content: NonNullable<ReactNode>
  followCursor?: boolean
  arrow?: boolean
  open?: boolean
}

function StyledTooltip({
  children,
  position = 'bottom',
  type = 'default',
  content,
  followCursor = false,
  arrow = false,
  open,
}: Props) {
  return (
    <Tooltip
      open={open}
      disableInteractive
      followCursor={followCursor}
      placement={position}
      classes={{
        popper: `${styles.tooltip} ${styles[type]}`,
        arrow: styles.arrow,
      }}
      title={
        type === 'default' && content !== '' ? (
          <p className={styles.content}>{content}</p>
        ) : (
          content
        )
      }
      arrow={arrow}
      PopperProps={{ style: { zIndex: 10000 } }}
    >
      {children}
    </Tooltip>
  )
}

export default StyledTooltip
